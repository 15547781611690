import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

import Gallery from "@browniebroke/gatsby-image-gallery"

import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Video from "../../components/Video/Video"
import Seo from "../../components/Seo"

const NewsPage = ({ data: { previous, next, newIndividual } }) => {
  const images = newIndividual.NewGQL.imagesGallery?.map(
    image => image.localFile.childImageSharp
  )

  return (
    <React.Fragment>
      <Seo
        title={newIndividual.NewGQL.title}
        description={""}
        image={
          newIndividual.NewGQL.thumbnailImage.localFile.childImageSharp
            .gatsbyImageData.images.fallback.src
        }
      />

      <Navbar />
      <article className="marginTopNavbar">
        <div className="main-container">
          <header className="news-header">
            <h1 className="news-header__title">
              {parse(newIndividual.NewGQL.title)}
            </h1>
            <p className="news-header__date">
              <time>{newIndividual.date}</time>
            </p>

            <div className="grid-8-col">
              <div className="col-2"></div>

              <GatsbyImage
                draggable="false"
                className="col-4 image-border"
                alt={newIndividual.NewGQL.title}
                image={
                  newIndividual.NewGQL.thumbnailImage.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            </div>
            <span className="news-header__scroll">Scroll Down ↓</span>
          </header>
        </div>
        <div className="horizontal-line" />
        <div className="main-container">
          <section className="news-body grid-8-col">
            <div className="col-1"></div>
            <div className="col-4">
              {parse(newIndividual.NewGQL.text)}

              {newIndividual.NewGQL.imagesGallery && (
                <>
                  <br />
                  <br />
                  <h2>Image Gallery</h2>
                  <br />
                  <Gallery
                    // colWidth={"25%"}
                    // mdColWidth={"50%"}
                    rowMargin={0}
                    images={images}
                  />
                </>
              )}

              {newIndividual.NewGQL.videoGallery && (
                <>
                  <br />
                  <br />
                  <h2>Videos</h2>
                  <br />
                  {newIndividual.NewGQL.videoGallery.map(
                    (v, vIndex) =>
                      v.video?.mediaItemUrl && (
                        <Video
                          key={vIndex}
                          videoSrcURL={v.video.mediaItemUrl}
                          videoTitle={v.video.title}
                        />
                      )
                  )}
                  <br />
                  {newIndividual.NewGQL.videoGallery.map(
                    (vi, vIndex) =>
                      vi.videoUrl !== null && (
                        <Video
                          key={vIndex}
                          videoSrcURL={vi.videoUrl}
                          videoTitle={""}
                        />
                      )
                  )}
                </>
              )}
            </div>
          </section>
        </div>
        <div className="horizontal-line" />
        <div className="main-container">
          <nav className="link-pages-nav">
            <ul className="link-pages-nav__ul">
              <li className="link-pages-nav__ul__left">
                {previous && (
                  <>
                    <span>Previous</span> <br />
                    <Link to={previous.uri} rel="prev">
                      {parse(previous.title)}
                    </Link>
                  </>
                )}
              </li>
              <li className="link-pages-nav__ul__center">
                <Link to="/news">More News</Link>
              </li>
              <li className="link-pages-nav__ul__right">
                {next && (
                  <>
                    <span>Next</span> <br />
                    <Link to={next.uri} rel="next">
                      {parse(next.title)}
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </article>
      <Footer />
    </React.Fragment>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query NewIndividualById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    newIndividual: wpNew(id: { eq: $id }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      NewGQL {
        title
        text
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imagesGallery {
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        videoGallery {
          video {
            title
            mediaItemUrl
          }
          videoUrl
        }
      }
    }
    previous: wpNew(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpNew(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
